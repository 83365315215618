// Fully reset store & local storage
export const RESET_STORE = 'RESET_STORE'

// Async request processing indicator
export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'

// User
export const USER_SIGNUP = 'USER_SIGNUP'
export const SEND_VERIFICATION_MAIL = 'SEND_VERIFICATION_MAIL'
export const VERIFY_EMAIL_TOKEN = 'VERIFY_EMAIL_TOKEN'
export const USER_LOGIN = 'USER_LOGIN'
export const USER_NO_PASSWORD_LOGIN = 'USER_NO_PASSWORD_LOGIN'
export const GET_ME = 'GET_ME'
export const SET_ME = 'SET_ME'
export const SAVE_ME = 'SAVE_ME'

// Captcha
export const GET_CAPTCHA = 'GET_CAPTCHA'

// Subscribers
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS'

// Subscriber
export const SUBSCRIBER_LOGIN = 'SUBSCRIBER_LOGIN'
export const GET_UNVERIFIED_SUBSCRIBER = 'GET_UNVERIFIED_SUBSCRIBER'
export const GET_VERIFIED_SUBSCRIBER = 'GET_VERIFIED_SUBSCRIBER'
export const GET_SUBSCRIBER = 'GET_SUBSCRIBER'
export const SET_SUBSCRIBER = 'SET_SUBSCRIBER'
export const SAVE_SUBSCRIBER = 'SAVE_SUBSCRIBER'
export const SUBSCRIBER_NEW_VERIFICATION = 'SUBSCRIBER_NEW_VERIFICATION'
export const VERIFY_SUBSCRIBER = 'VERIFY_SUBSCRIBER'

// Subscriber Subscriptions
export const GET_SUBSCRIBER_SUBSCRIPTIONS = 'GET_SUBSCRIBER_SUBSCRIPTIONS'
export const GET_SUBSCRIBER_SUBSCRIPTION = 'GET_SUBSCRIBER_SUBSCRIPTION'
export const SAVE_SUBSCRIBER_SUBSCRIPTION = 'SAVE_SUBSCRIBER_SUBSCRIPTION'

// Terms
export const SEND_ACCEPT_TERMS_CODE = 'SEND_ACCEPT_TERMS_CODE'
export const POST_ACCEPT_TERMS_CODE = 'POST_ACCEPT_TERMS_CODE'


// Senders
export const GET_SENDERS = 'GET_SENDERS'
export const GET_SENDERS_QUIETLY = 'GET_SENDERS_QUIETLY'
export const GET_SENDERS_SUGGESTIONS = 'GET_SENDERS_SUGGESTIONS'

// Sender
export const GET_SENDER = 'GET_SENDER'
export const GET_SENDER_TAGS = 'GET_SENDER_TAGS'

// Companies
export const GET_COMPANIES = 'GET_COMPANIES'

// Tiny URLs
export const TINY_URL = 'TINY_URL'
export const TINY_BATCH_URLS = 'TINY_BATCH_URLS'

// Statistics
export const GET_SUBSCRIPTIONS_STAT = 'GET_SUBSCRIPTIONS_STAT'

// Manage
// Uselog
export const GET_USERLOG_FILES = 'GET_USERLOG_FILES'
export const GET_USERLOG = 'GET_USERLOG'
// Subscribers
export const MANAGE_GET_SUBSCRIBERS = 'MANAGE_GET_SUBSCRIBERS'
// Subscriptions
export const MANAGE_SAVE_SUBSCRIPTION = 'MANAGE_SAVE_SUBSCRIPTION'

// About
export const SEND_FEEDBACK_FORM = 'SEND_FEEDBACK_FORM'

