export default  [
    {
        path: '/senders',
        meta: {
            tag: 'senders',
        },
        component: () => import('@/views/Nested'),
        children: [
            // Senders suggestions
            {
                path: 'suggestions/:sender_id?',
                name: 'senders.suggestions',
                component: () => import('@/views/senders/SendersSuggestions'),
                props: true,
            }
        ]
    }
]
