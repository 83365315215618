// Authenicated user
export const default_auth_user = function(){
    return {
        api_token: undefined,
        email_verified_at: undefined,
        boot(){
            Object.assign(this, JSON.parse(localStorage.auth_user ?? '{}'))

            return this
        },
        // Check if current user is logged in
        loggedIn(){
            return !!this.uid
        },
        // Check if current user email is verified
        emailVerified(){
            return !!this.email_verified_at
        },
    }
}

export const default_subscriber = function(){
    return {
        uid: undefined,
        verified_at: undefined,
        resend_timeout: 0,
        check_terms_accepted: false,
        boot(){
            Object.assign(this, JSON.parse(localStorage.subscriber ?? '{}'))

            return this
        },
        loggedIn: function(){
            return  !!this.uid
        },
        isVerified: function(){
            return  !!this.verified_at
        },
        hasCompany: function(){
            return  !!this.company
        },
    }
}

export const default_state = function(){
    return {
        // Async queries processing indicator counter
        loading: 0,
        // Authenticated user
        auth_user: default_auth_user(),
        // Subscriber
        subscriber: default_subscriber(),
    }
}
