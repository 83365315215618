export default  [
    {
        path: '/manage',
        name: "manage",
        meta: {
            tag: 'manage',
        },
        component: () => import('@/views/manage/Dashboard'),
    },
    {
        path: '/manage',
        meta: {
            tag: 'manage',
        },
        component: () => import('@/views/Nested'),
        children: [
            {
                path: 'tinyurl',
                name: 'manage.tinyurl',
                component: () => import('@/views/manage/TinyURL'),
                props: true,
            },
            {
                path: 'subscribers',
                name: 'manage.subscribers',
                component: () => import('@/views/manage/ManageSubscribers'),
                props: true,
            },
            {
                path: 'userlog',
                name: 'manage.userlog',
                component: () => import('@/views/manage/UserLog'),
                props: true,
            },
        ]
    },
]
