export default  [
    {
        path: '/subscribers',
        name: "subscribers",
        meta: {
            tag: 'subscribers',
        },
        component: () => import('@/views/subscribers/Subscribers'),
    },
]
