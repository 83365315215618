const vars = {
    data: function () {
        return {
            minDesktopWidth: 1600,
            maxSmallDesktopWidth: 1366,
            maxTabletWidth: 1024,
            maxMobileWidth: 768,
        }
    }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false;

require('./layouts');

require('./moment');

require('./font-awesome');

import i18n from './i18n/index'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { lodash: lodash })

// Element UI
import ElementUI from 'element-ui';
Vue.use(ElementUI);

// Animate.css
import 'animate.css';

// Vue html meta tags manager
import VueMeta from 'vue-meta';
Vue.use(VueMeta);

// Copy to clipboard plugin
import Clipboard from 'v-clipboard';
Vue.use(Clipboard);

require('./interceptors');

import AppMessage from './mixins/AppMessage';

new Vue({
    mixins: [
        vars,
        AppMessage
    ],
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
