import Vue from 'vue'
import moment from 'moment'

moment.updateLocale('ru', {
    longDateFormat : {
        lll: "D MMM YYYY в H:mm",
        llll: "D MMM YYYY в H:mm:ss",
    }
})

Vue.prototype.$moment = moment
