import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { default_state } from './defaults'

import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export default new Vuex.Store({
    state: default_state(),
    getters,
    actions,
    mutations,
})
