<template lang="pug">
footer
  section.compact
    .section-content-wrapper
      ul
        li <b>Opt-in</b>
        li Сервис управления персональными подписками через смс
        li Тел: +7 (495) 970-04-03
        li E-mail: info@opt-in.ru
        li(v-if="subscriber.loggedIn()")
          router-link(:to="{name: 'agreement'}") Соглашение об использовании Сайта
      ul
        li.copyright © 2021 ООО "ОКСК". Все права защищены
    template(v-if="beeline_id")
      img.tracking_pixel(:src="`https://0a44242d31a0486ba4f594212ad5b9b0.ops.beeline.ru?id=${beeline_id}`")
    template(v-if="subscriber.loggedIn() && this.subscriber.tracking_uid")
      img.tracking_pixel(:src="aidataTrackerURL" width="0")
      img.tracking_pixel(v-if="false" :src="kleverTrackerURL" width="0")
</template>

<script>
import {mapGetters} from "vuex"
import {sha256} from 'js-sha256'

export default {
  data() {
    return {
      dash: '&mdash;',
    }
  },
  computed: {
    ...mapGetters([
      'subscriber'
    ]),
    aidataTrackerURL() {
      return '//x01.aidata.io/0.gif?pid=6016534&id=' + this.subscriber.tracking_uid;
    },
    kleverTrackerURL() {
      return ''
      //return 'https://sync.1dmp.io/pixel.gif?cid=60b88713-77e8-4b4d-a094-5413e6a2591a&brid=349355bd-7a6a-4dde-a172-19393c2a8002&pid=w&uid=' + this.subscriber.tracking_uid;
    },
    beeline_id(){
      return this.subscriber.loggedIn() ? sha256(this.subscriber.phone + '') : null
    },
  },
}
</script>

<style lang="scss" scoped>
footer {
  .tracking_pixel {
    position: absolute;
    left: -10000px;
  }

  .section-content-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .skolkovo {
    padding-bottom: $padding18;

    img {
      display: inline-block;
      width: 60px;
    }
  }

  .copyright {
    @include font--small;
  }

  @media (max-width: $max-width--tablets) {
    .section-content-wrapper {
      display: block;
      text-align: center;

      ul {
        &:last-child {
          margin-top: $padding12;
        }
      }
    }
  }
}
</style>
