export default  [
    {
        path: '/agreement',
        name: 'agreement',
        meta: {
            tag: 'agreement',
        },
        component: () => import('@/views/agreement/TermsAgreement'),
    },
    {
        path: '/agreement',
        meta: {
            tag: 'agreement',
        },
        component: () => import('@/views/Nested'),
        children: [
            {
                path: 'affiliates',
                name: 'agreement.affiliates',
                component: () => import('@/views/agreement/Affiliates'),
            },
        ]
    },
]
