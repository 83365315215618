export default  [
    {
        path: '/users',
        name: "users",
        meta: {
            tag: 'users',
        },
        component: () => import('@/views/users/List'),
    },
]
