export default  [
    {
        path: '/companies',
        name: "companies",
        meta: {
            tag: 'companies',
        },
        component: () => import('@/views/companies/List'),
    },
]
