export default  [
    {
        path: '/sender',
        name: 'sender.edit',
        meta: {
            tag: 'senders',
        },
        component: () => import('@/views/sender/Edit'),
    },
    {
        path: '/sender',
        meta: {
            tag: 'senders',
        },
        component: () => import('@/views/Nested'),
        children: [
            {
                path: ':id',
                name: 'sender.details',
                component: () => import('@/views/sender/Details'),
                props: true,
            },
        ]
    },
]
