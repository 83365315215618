import axios from "axios"
import router from "./router"
import store from "./store"

axios.interceptors.request.use(function (config) {
    config.headers.Accept = 'application/json'

    // Auth user
    if(store.getters.auth_user.api_token) config.headers.Authorization = `Bearer ${ store.getters.auth_user.api_token }`

    // Subscriber
    if(store.getters.subscriber.uid) config.headers.Subscriber = `${ store.getters.subscriber.uid }`

    return config
}, function (error) {
    // Do something with request error
    return Promise.reject(error)
})

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response
}, function (error) {
    // Do something with response error
    if(Object.hasOwnProperty.call(error, 'response')){
        switch (error.response.status) {
            case 307:
                if(error.response.data.route !== undefined) router.push({ name: error.response.data.route })
                break
            case 401:
                store.commit('RESET_STORE')

                router.push({ name: 'index' }).catch(failure => {})
                break
            case 403:
                router.push({ name: 'forbidden' }).catch(failure => {})
                break
            case 404:
                // router.push({ name: 'error.not_found' }).catch(failure => {})
                break
        }
    }else{
        //console.log(error)
    }

    return Promise.reject(error)
})
