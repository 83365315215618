export default {
    loading: state => {
        return state.loading > 0
    },
    aside_visible: state => {
        return state.aside_visible
    },
    // Auth user
    auth_user: state => {
        return state.auth_user
    },
    // Subscriber
    subscriber: state => {
        return state.subscriber
    },
}
