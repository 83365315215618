'use strict'

module.exports = {
    name: 'AppMessage',
    methods: {
        pushAppMessages(messages, type = 'error'){
            try{
                switch (typeof messages) {
                    default:
                        throw new Error('message.undefined')
                    case 'object':
                        if(Object.prototype.hasOwnProperty.call(messages, 'message')){
                            this.$message[type](messages.message)
                        }else{
                            for(const index in messages){
                                setTimeout(()=>this.$message[type](messages[index]), 100)
                            }
                        }
                        break
                    case 'string':
                            this.$message[type](messages)
                        break
                }
            }catch (error) {
                this.$message[type](error.message)
            }
        }
    }
}
