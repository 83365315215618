export default  [
    // Ordinary subscriptions list
    {
        path: '/subscriptions',
        name: "subscriptions",
        meta: {
            tag: 'subscriptions',
        },
        component: () => import('@/views/subscriptions/Subscriptions'),
    },
    {
        path: '/subscriptions',
        meta: {
            tag: 'subscriptions',
        },
        component: () => import('@/views/Nested'),
        children: [
            // Autologin + modify a subscription
            {
                path: ':uid/:id',
                name: 'subscription.modify',
                component: () => import('@/views/subscriptions/Subscriptions'),
                props: true,
            },
        ]
    },
]
