<template lang="pug">
  .default.layout
    Header
    main
      slot
    Footer
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'DefaultLayout',
  components: {
    Header,
    Footer,
  },
}
</script>

<style lang="scss" scoped>
.layout {
  min-height: 100%;
}
</style>
