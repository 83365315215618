<template lang="pug">
header
	section.supercompact
		.always.section-content-wrapper
			.logo
				a(@click="navigateTo({ name: 'index' })")
					img(src="/storage/logo_horizontal_white.png")
				a(href="http://www.sk.ru" target="_blank")
					img.skolkovo(src="/storage/sk_resident_en_small.png")
			.menu-control
				.profile-info(v-if="subscriber.loggedIn()") {{ subscriber.phone }} {{ subscriber.company ? subscriber.company.name : '' }}
				.icon(@click="menu_visible = !menu_visible")
					font-awesome-icon(:icon="!menu_visible ? 'bars' : 'times'" far)
	section.menu.supercompact(v-if="menu_visible")
		.section-content-wrapper
			.menu-row
				template(v-if="subscriber.loggedIn()")
					nav.nav12
						a(@click.prevent="navigateTo({ name: 'senders.suggestions' })") Отправители
						a(@click.prevent="navigateTo({ name: 'subscriptions' })")  Подписки
						a(@click.prevent="navigateTo({ name: 'subscription.create' })") Новая подписка
					nav.nav12
						a(@click.prevent="navigateTo({ name: 'about' })") О сервисе
						template(v-if="subscriber.hasCompany()")
							a(@click.prevent="navigateTo({ name: 'manage.subscribers' })") Абоненты
						a(@click.prevent="logout") Выйти
				template(v-else)
					nav
						a(@click.prevent="navigateTo({ name: 'about' })") О сервисе
						a(@click.prevent="navigateTo({ name: 'partners' })") Партнёрам
						a(@click.prevent="navigateTo({ name: 'subscriber.login' })") Войти
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {RESET_STORE} from "@/store/types"

export default {
	name: "Header",
	data() {
		return {
			menu_visible: false,
		}
	},
	computed: {
		...mapGetters([
			'subscriber',
			'auth_user',
		]),
	},
	methods: {
		...mapMutations([
			RESET_STORE,
		]),
		navigateTo(route) {
			this.menu_visible = false
			this.$router.push(route)
		},
		logout() {
			this.RESET_STORE()

			this.menu_visible = false

			if (this.$route.name !== 'index') this.$router.push({name: 'index'})
		},
	}
}
</script>

<style lang="scss" scoped>
header {
	@include font--medium;

	position: sticky;
	position: -webkit-sticky;
	z-index: 1000;
	top: 0;
	width: 100%;
	background: $color--black;

	.always {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.logo {
			$height: 30px;

			display: flex;
			align-items: center;

			img {
				height: $height - 2;
			}

			.skolkovo {
				height: $height;
				margin-left: $padding14;
			}
		}

		.menu-control {
			display: flex;
			align-items: center;

			.profile-info {
				padding-right: $padding14;
			}

			.icon {
				display: block;
				width: 20px;
				text-align: right;
				cursor: pointer;
			}
		}
	}

	.menu {
		@include font--medium;

		padding-top: 0;
		text-align: center;
		color: $color--background;

		nav {
			width: 100%;

			a {
				display: block;
				padding: $padding18;
				color: inherit;
				transition: .2s;
				color: $color--primary;

				&:hover {
					background: $color--gray;
				}
			}
		}

		&-row {
			@include border($border: 1px solid $color--primary, $position: top);
			display: flex;
			padding-top: $padding14;

			.nav12 {
				width: 50%;
			}
		}


		@media (max-width: $max-width--tablets) {
			&-row {
				display: block;

				.nav12 {
					width: 100%;
				}
			}
		}
	}
}
</style>
