import * as types from "./types"

import { default_state, default_subscriber, default_auth_user } from './defaults'

export default {
    [types.RESET_STORE](state){
        // Clear local storage
        window.localStorage.clear()
        // Set the default app state
        Object.assign(state, default_state())
    },
    [types.START_LOADING](state){
        state.loading += 1
    },
    [types.STOP_LOADING](state){
        state.loading -= 1
    },
    [types.SET_ME]: (state, auth_user) => {
        // Merge user data with the default
        state.auth_user = Object.assign(default_auth_user(), ...auth_user)
        // Save user data in the local storage
        localStorage.auth_user = JSON.stringify(auth_user)
    },
    [types.SET_SUBSCRIBER]: (state, subscriber) => {
        state.subscriber = Object.assign(default_subscriber(), subscriber)
        // Save user data in the local storage
        localStorage.subscriber = JSON.stringify(subscriber)
    },
}
