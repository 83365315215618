<template lang="pug">
    .index.view
        section.about
            .section-content-wrapper
                h1 Отпишитесь от спама
                h3 Заблокируйте получение рекламных сообщений, выбрав из списка отправителей, или указав имя самостоятельно.
                h2 Легко и бесплатно
        section.centered.login(v-if="!subscriber.loggedIn()")
            .section-content-wrapper
                h2 Узнайте, от чего вы можете отписаться
                h3(v-if="login_form.step === 0") Укажите свой номер телефона:
                h3(v-else-if="login_form.step === 1") Повторите символы с картинки:
                .login-form
                    el-form(ref="login_form" :model="login_form" @keydown.enter.native.prevent="login" :rules="login_form_rules")
                        .login-form-step-0(v-show="login_form.step === 0")
                            el-form-item
                                nobr +7
                            el-form-item(prop="code", label="")
                                el-input.code(v-model="login_form.code" placeholder="000" maxlength="3")
                            el-form-item(prop="number", label="")
                                el-input.number(ref="login_form_number" v-model="login_form.number" placeholder="0000000" maxlength="7")
                        .login-form-step-1(v-show="login_form.step === 1")
                            el-form-item
                                img(:src="captcha.img")
                                el-progress(:percentage="captcha_progress" :status="captcha_progress_status" :stroke-width="2" :show-text="false")
                            el-form-item(prop="captcha", label="")
                                el-input.captcha(ref="login_form_captcha" v-model="login_form.captcha" maxlength="4")
                            el-button(@click="login" type="primary") Далее
        section.centered.senders-suggestions(v-else)
            .section-content-wrapper
                h2 Популярные отправители
                h4 Мы составили список отправителей, рекламные сообщения от которых люди получают чаще всего.
                h3 Отпишитесь от любого из них
                .table
                    .th
                        .td Отправитель
                        .td Канал
                        .td Статус
                    .tr.striped(v-for="sender in senders.data" :key="sender.id")
                        .td.name.tablets--inline
                            b {{ sender.name }}
                        .td.channel.tablets--inline SMS
                        .td(@click.stop="saveSubscriberSubscription(sender)")
                            el-switch(v-model="sender.unsubscribed" :disabled="!subscriber.hasCompany() && !subscriber.terms_accepted_at" :active-text="sender.unsubscribed === false ? 'Подписан' : sender.unsubscribed === true ? 'Отписан' : 'Отпишитесь'" :class="sender.unsubscribed ? 'active' : sender.unsubscribed === false ? 'inactive' : ''")
                el-button(@click="$router.push({name: 'senders.suggestions'})" type="primary") Полный список отправителей
        HowItWorks
</template>

<script>
import {mapActions, mapMutations, mapGetters} from "vuex"
import {
    GET_CAPTCHA,
    SUBSCRIBER_LOGIN,
    GET_SUBSCRIBER_SUBSCRIPTIONS,
    GET_SENDERS_SUGGESTIONS,
    SAVE_SUBSCRIBER_SUBSCRIPTION,
    SET_SUBSCRIBER,
} from "@/store/types"

import HowItWorks from '@/components/HowItWorks'

const default_login_form = () => {
    return {
        step: 0,
        code: '',
        number: '',
        captcha: '',
        phone: '',
    }
}

const default_senders = () => {
    return {
        current_page: 1,
        total: 0,
    }
}

export default {
    components: {
        HowItWorks,
    },
    metaInfo() {
        return {
            title: this.title
        }
    },
    data() {
        return {
            captcha_lifetime: 60,
            captcha_timeout: undefined,
            captcha_progress: 100,
            captcha_progress_status: 'success',
            captcha_progress_timeout: undefined,
            captcha: {
                img: undefined,
            },
            login_form: default_login_form(),
            login_form_rules: {
                code: [
                    {required: true, message: 'Код города', trigger: 'blur'},
                    {min: 3, max: 3, message: '3 цифры', trigger: 'blur'},
                ],
                number: [
                    {required: true, message: 'Номер телефона', trigger: 'blur'},
                    {min: 7, max: 7, message: '7 цифр', trigger: 'blur'},
                ],
                captcha: [
                    {required: true, message: 'Символы', trigger: 'blur'},
                    {min: 4, max: 4, message: '4 символа', trigger: 'blur'},
                ],
            },
            subscriptions: [],
            senders: default_senders(),
        }
    },
    computed: {
        ...mapGetters([
            'subscriber'
        ]),
        phone() {
            return '7' + this.login_form.code + this.login_form.number
        },
        title() {
            return 'Отпишитесь от спама'
        },
    },
    created() {
        if (this.subscriber.loggedIn()) {
            this.getSubscriberSubscriptions().then(() => {
                this.getSenders()
            })
        }

        this.resetLoginForm()

        this.$watch('login_form', () => {
            if (this.login_form.code.length === 3 && this.login_form.step === 0) this.$refs.login_form_number.focus()
            if (this.login_form.code.length === 3 && this.login_form.number.length === 7 && this.login_form.step === 0) {
                this.login_form.step = 1

                this.$refs.login_form_captcha.focus();

                if (window.gtag !== undefined) {
                    gtag('event', 'type_phone', {
                        'event_category': 'onboarding'
                    })
                    console.log('GA goal reached: type_phone')
                }

                if (window.ym !== undefined) {
                    window.ym(74625946, 'reachGoal', 'type_phone')
                    console.log('YM goal reached: type_phone')
                    window.ym(74625946, 'hit', '/#captcha')
                    console.log('URL hit: /#captcha')
                }
            }
        }, {
            // Run automatically?
            immediate: false,
            // Watch all properties changes
            deep: true
        })
    },
    beforeRouteLeave(to, from, next) {
        this.resetCaptchaProgress()

        next()
    },
    methods: {
        ...mapActions([
            GET_CAPTCHA,
            SUBSCRIBER_LOGIN,
            GET_SUBSCRIBER_SUBSCRIPTIONS,
            GET_SENDERS_SUGGESTIONS,
            SAVE_SUBSCRIBER_SUBSCRIPTION,
        ]),
        ...mapMutations([
            SET_SUBSCRIBER,
        ]),
        resetLoginForm() {
            this.login_form = default_login_form()

            this.refreshCaptcha()
        },
        resetCaptchaProgress() {
            window.clearTimeout(this.captcha_timeout)
            window.clearTimeout(this.captcha_progress_timeout)

            this.captcha_progress = 100
            this.captcha_progress_status = 'success'
        },
        refreshCaptcha() {
            this.resetCaptchaProgress()

            return this.GET_CAPTCHA().then((captcha) => {
                Object.assign(this.captcha, captcha)

                this.captcha_timeout = window.setTimeout(() => {
                    this.refreshCaptcha()
                }, this.captcha_lifetime * 1000)
            }).then(() => {
                this.progressCaptcha()
            }).catch((error) => {
                if (error.response.data.errors) {
                    this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                        return this.$t(message)
                    }))

                    return Promise.reject(error)
                }
            })
        },
        progressCaptcha() {
            this.captcha_progress_timeout = window.setTimeout(() => {
                if (!this.captcha_progress) this.resetCaptchaProgress()

                this.captcha_progress -= .1

                if (this.captcha_progress < 66.6) this.captcha_progress_status = 'warning'
                if (this.captcha_progress < 33.3) this.captcha_progress_status = 'exception'

                this.progressCaptcha()
            }, this.captcha_lifetime)
        },
        login() {
            this.$refs['login_form'].validate((valid) => {
                if (valid) {
                    this.SUBSCRIBER_LOGIN({...this.login_form, ...{phone: this.phone, key: this.captcha.key}})
                        .then(() => {
                            this.$router.push({
                                name: 'subscriber.verification',
                                params: {verification_uid: this.subscriber.verification_uid}
                            })
                        }).catch((error) => {
                        this.resetLoginForm()

                        if (error.response.data.errors) {
                            this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                                return this.$t(message)
                            }))
                        }

                        return Promise.reject(error)
                    })
                } else return false;
            })
        },
        logout() {
            this.RESET_STORE()
        },
        getSubscriberSubscriptions() {
            return this.GET_SUBSCRIBER_SUBSCRIPTIONS({subscriber: this.subscriber}).then((subscriptions) => {
                Object.assign(this.subscriptions, subscriptions)
            }).catch((error) => {
                if (error.response.data.errors) {
                    this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                        return this.$t(message)
                    }))
                }

                return Promise.reject(error)
            })
        },
        getSenders() {
            return this.GET_SENDERS_SUGGESTIONS({page: this.senders.current_page}).then((senders) => {
                senders.data = this.mergeSenderStatus(senders.data)

                this.senders = senders
            }).catch((error) => {
                if (error.response.data.errors) {
                    this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                        return this.$t(message)
                    }))
                }

                return Promise.reject(error)
            })
        },
        mergeSenderStatus(senders) {
            return this._.map(senders, (sender) => {
                let subscription = this._.filter(this.subscriptions, (subscription) => {
                    return subscription.sender_name === sender.name
                })[0]

                if (subscription !== undefined) Object.assign(sender, {unsubscribed: subscription.unsubscribed})

                return sender
            })
        },
        saveSubscriberSubscription(sender) {
            this.SET_SUBSCRIBER(Object.assign(this.subscriber, {check_terms_accepted: true}));

            if(this.subscriber.hasCompany() || (this.subscriber.terms_accepted_at !== null)){
                const subscription = {
                    channel_alias: sender.channel_alias,
                    sender_name: sender.name,
                    unsubscribed: sender.unsubscribed,
                }
                return this.SAVE_SUBSCRIBER_SUBSCRIPTION(subscription)
                    .then((subscription) => {
                        if (subscription.unsubscribed) {
                            if (window.gtag !== undefined) {
                                gtag('event', 'tap_unsubscribe', {
                                    'event_category': 'onboarding'
                                })
                                console.log('GA goal reached: tap_unsubscribe')
                            }

                            if (window.ym !== undefined) {
                                window.ym(74625946, 'reachGoal', 'tap_unsubscribe')
                                console.log('YM goal reached: tap_unsubscribe')
                            }
                        }
                    }).catch((error) => {
                        if (error.response.data.errors) {
                            this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                                return this.$t(message)
                            }))
                        }
                    });
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.index {
    .about {
        color: white;
        text-align: center;
        background: radial-gradient(ellipse at top, lighten($color--black, 15%), transparent),
        radial-gradient(ellipse at bottom, $color--black, transparent);

        .title {
            @include font--heading;

            font-size: 46px;
        }

        h3 {
            width: 70%;
            margin: 0 auto;
        }

        @media (max-width: $max-width--tablets) {
            h3 {
                width: 100%;
            }
        }
    }

    .login {
        margin-top: 0;
        background: $color--background;
        color: $color--black;

        &-form {
            text-align: center;

            .el-form {
                display: inline-block;
                text-indent: 0;
                overflow: hidden;
            }

            &-step-0, &-step-1 {
                display: flex;
                align-items: center;

                > * {
                    display: block;
                    margin-right: .5rem;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .el-form-item {
                    margin-bottom: 0;
                }

                nobr {
                    @include font--medium;
                }

                .code {
                    width: 5em;
                }

                .number {
                    width: 10em;
                }

                .captcha {
                    width: 5em;
                }
            }
        }
    }

    .senders-suggestions {
        background: $color--background;
        color: $color--black;


        .el-button {
            margin-top: $padding12;
        }

        @media (max-width: $max-width--tablets) {
            .tr {
                @include border($position: bottom);

                padding: $padding18 0;
                text-align: left;

                .td {
                    padding-top: 0;

                    &.tablets--inline {
                        display: inline-block;
                    }

                    &.channel {
                        float: right;

                    }
                }
            }
        }
    }
}
</style>
