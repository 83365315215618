<template lang="pug">
  .empty.layout
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  body{
    background: none;
    color: $color--black;
  }
</style>
