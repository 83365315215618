export default  [
    // {
    //     path: '/signup',
    //     name: 'user.signup',
    //     component: () => import('@/views/user/Signup')
    // },
    {
        path: '/verify',
        name: 'user.verify',
        component: () => import('@/views/user/Verify')
    },
    {
        path: '/verify/:token',
        component: () => import('@/views/user/VerifyToken'),
        props: true
    },
    {
        path: '/login',
        name: 'user.login',
        component: () => import('@/views/user/Login')
    },
    // {
    //     path: '/login/:token',
    //     name: 'user.no_password_login',
    //     component: () => import('@/views/user/NoPasswordLogin'),
    //     props: true
    // },
    {
        path: '/profile',
        meta: {
            tag: 'user.profile',
        },
        component: () => import('@/views/Nested'),
        children: [
            {
                path: 'me',
                name: 'user.profile.me',
                component: () => import('@/views/user/profile/Me')
            },
            {
                path: 'company',
                name: 'user.profile.company',
                component: () => import('@/views/user/profile/Company')
            },
        ]
    },
]
