import * as types from "./types"
import axios from 'axios'

export default {
	// User
	[types.USER_SIGNUP]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/user/signup', payload)
				.then((response) => {
					commit(types.SET_ME, response.data)
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.SEND_VERIFICATION_MAIL]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/user/email/token/send', payload)
				.then((response) => {
					commit(types.SET_ME, response.data)
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.VERIFY_EMAIL_TOKEN]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/user/email/token/verify', payload)
				.then((response) => {
					commit(types.SET_ME, response.data)
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.USER_LOGIN]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/user/login', payload)
				.then((response) => {
					commit(types.SET_ME, response.data)
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.USER_NO_PASSWORD_LOGIN]: ({commit}, token) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.get('/api/user/login/' + token)
				.then((response) => {
					commit(types.SET_ME, response.data)
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.GET_ME]: ({commit}) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.get('/api/user/me')
				.then((response) => {
					commit(types.SET_ME, response.data)
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.SAVE_ME]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/user/me', payload)
				.then((response) => {
					commit(types.SET_ME, response.data)
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	// Captcha
	[types.GET_CAPTCHA]: ({commit}) => {
		return new Promise((resolve, reject) => {
			//commit(types.START_LOADING)

			axios.get('/captcha/api/flat')
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				//commit(types.STOP_LOADING)
			})
		})
	},
	// Subscriber
	[types.SUBSCRIBER_LOGIN]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/subscriber/login', payload)
				.then((response) => {
					commit(types.SET_SUBSCRIBER, response.data)
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.GET_SUBSCRIBER]: ({commit}, id) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.get(`/api/subscriber/${id}`)
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.SAVE_SUBSCRIBER]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.put(`/api/subscriber`, payload)
				.then((response) => {
					commit(types.SET_SUBSCRIBER, response.data)
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.GET_UNVERIFIED_SUBSCRIBER]: ({commit}, uid) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.get(`/api/subscriber/unverified/${uid}`)
				.then((response) => {
					commit(types.SET_SUBSCRIBER, response.data)
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.GET_VERIFIED_SUBSCRIBER]: ({commit}, uid) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.get(`/api/subscriber/${uid}`)
				.then((response) => {
					commit(types.SET_SUBSCRIBER, response.data)
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.SUBSCRIBER_NEW_VERIFICATION]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/subscriber/verification/new', payload)
				.then((response) => {
					commit(types.SET_SUBSCRIBER, response.data)
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.VERIFY_SUBSCRIBER]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/subscriber/verification', payload)
				.then((response) => {
					commit(types.SET_SUBSCRIBER, response.data)
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.GET_SUBSCRIPTIONS]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/subscriptions', payload)
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.GET_SUBSCRIBER_SUBSCRIPTIONS]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/subscriber/subscriptions', payload)
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.GET_SUBSCRIBER_SUBSCRIPTION]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post(`/api/subscriber/subscription`, payload)
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.SAVE_SUBSCRIBER_SUBSCRIPTION]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.put('/api/subscriber/subscription', payload)
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
    // Terms
    [types.SEND_ACCEPT_TERMS_CODE]: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit(types.START_LOADING)

            axios.get('/api/terms/code')
                .then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                reject(error)
            }).finally(() => {
                commit(types.STOP_LOADING)
            })
        })
    },
    [types.POST_ACCEPT_TERMS_CODE]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit(types.START_LOADING)

            axios.post('/api/terms/code', payload)
                .then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                reject(error)
            }).finally(() => {
                commit(types.STOP_LOADING)
            })
        })
    },
	// Senders
	[types.GET_SENDERS]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/senders', payload)
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.GET_SENDERS_QUIETLY]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			axios.post('/api/senders', payload)
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			})
		})
	},
	[types.GET_SENDERS_SUGGESTIONS]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/senders/suggestions', payload)
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	// Sender
	[types.GET_SENDER]: ({commit}, id) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.get(`/api/sender/${id}`)
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.GET_SENDER_TAGS]: ({commit}, id) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post(`/api/sender/tags`)
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	// Companies
	[types.GET_COMPANIES]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/companies', payload)
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	// Tiny URLs
	[types.TINY_URL]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/tinyurl', payload)
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	[types.TINY_BATCH_URLS]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			const form_data = new FormData()
			form_data.append('sender_name', payload.sender_name)
			form_data.append('batch', payload.file)

			axios.post('/api/tinyurl/batch', form_data, {
				headers: {'Content-Type': 'multipart/form-data'},
			}).then((response) => {
				resolve(response.data)
			}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	// Statistics
	[types.GET_SUBSCRIPTIONS_STAT]: ({commit}) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING)

			axios.post('/api/stat/subscriptions')
				.then((response) => {
					resolve(response.data)
				}).catch((error) => {
				reject(error)
			}).finally(() => {
				commit(types.STOP_LOADING)
			})
		})
	},
	// Manage
	// Userlog
	[types.GET_USERLOG_FILES]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING);

			axios.post('/api/manage/userlog/files', payload)
			.then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			}).finally(() => {
				commit(types.STOP_LOADING);
			})
		})
	},
	[types.GET_USERLOG]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING);

			axios.post('/api/manage/userlog', payload)
			.then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			}).finally(() => {
				commit(types.STOP_LOADING);
			})
		})
	},
	[types.MANAGE_GET_SUBSCRIBERS]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING);

			axios.post('/api/manage/subscribers', payload)
			.then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			}).finally(() => {
				commit(types.STOP_LOADING);
			})
		})
	},
	[types.MANAGE_SAVE_SUBSCRIPTION]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING);

			axios.post('/api/manage/subscriptions/save', payload)
			.then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			}).finally(() => {
				commit(types.STOP_LOADING);
			})
		})
	},
    // About
	[types.SEND_FEEDBACK_FORM]: ({commit}, payload) => {
		return new Promise((resolve, reject) => {
			commit(types.START_LOADING);

			axios.post('/api/about/feedback', payload)
			.then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			}).finally(() => {
				commit(types.STOP_LOADING);
			})
		})
	},
}
