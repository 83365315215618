export default  [
    {
        path: '/subscriber',
        meta: {
            tag: 'subscriber',
        },
        component: () => import('@/views/Nested'),
        children: [
            {
                path: 'login',
                name: 'subscriber.login',
                component: () => import('@/views/subscriber/SubscriberLogin')
            },
            {
                path: 'login/:uid',
                name: 'subscriber.autologin',
                component: () => import('@/views/subscriber/AutoLogin'),
                props: true,
            },
            {
                path: 'login/:uid/:sender_id',
                name: 'subscriber.unsubscribe',
                component: () => import('@/views/subscriber/AutoLogin'),
                props: true,
            },
            {
                path: 'verification/:verification_uid',
                name: 'subscriber.verification',
                component: () => import('@/views/subscriber/SubscriberVerification'),
                props: true,
            },
        ]
    },
]
