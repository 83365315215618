import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'index',
		meta: {
			//layout: 'clean',
		},
		component: Index
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('@/views/About'),
	},
	{
		path: '/partners',
		name: 'partners',
		component: () => import('@/views/Partners'),
	},
	{
		path: '/forbidden',
		name: 'forbidden',
		component: () => import('@/views/errors/Forbidden'),
	},
]

import manage_routes from './manage'
import users_routes from './users'
import user_routes from './user'
import senders_routes from './senders'
import sender_routes from './sender'
import subscribers_routes from './subscribers'
import subscriber_routes from './subscriber'
import subscriptions_routes from './subscriptions'
import subscription_routes from './subscription'
import company_routes from './companies'
import agreement_routes from './agreement'
import docs_routes from './docs'

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		...routes,
		...manage_routes,
		...users_routes,
		...user_routes,
		...senders_routes,
		...sender_routes,
		...subscribers_routes,
		...subscriber_routes,
		...subscriptions_routes,
		...subscription_routes,
		...company_routes,
		...agreement_routes,
		...docs_routes,
	],
	scrollBehavior(to, from, savedPosition) {
		if (window.ym !== undefined) {
			window.ym(74625946, 'hit', to.path)
			console.log('URL hit:', to.path)
		}

		if (to.hash) {
			return {selector: to.hash}
		} else if (savedPosition) {
			return savedPosition
		} else {
			return {x: 0, y: 0}
		}
	}
})

export default router
