import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18nOptions = {
    locale: 'ru',
    fallbackLocale: 'en',
    silentFallbackWarn: true
}

// Pluralisation method
VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
    // this === VueI18n instance, so the locale property also exists here
    if (this.locale !== 'ru') {
        if(choicesLength === 1) return 0
        if(choice === 0){
            return 0
        }else if(choice === 1){
            return 1
        }else if(choicesLength > 2){
            return 2
        } else {
            return 1
        }
    }else{
        if (choice === 0) {
            return 0
        }

        const teen = choice > 10 && choice < 20

        const endsWithOne = choice % 10 === 1

        if (!teen && endsWithOne) {
            return 1
        }

        if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
            return 2
        }

        return (choicesLength < 4) ? 2 : 3
    }
}

const i18n = new VueI18n(i18nOptions)

export default i18n
