export default  [
    // New subscription
    {
        path: '/subscription',
        name: 'subscription.edit',
        meta: {
            tag: 'subscriptions',
        },
        component: () => import('@/views/subscription/Edit'),
    },
    // Subscription that exists
    {
        path: '/subscription',
        meta: {
            tag: 'subscriptions',
        },
        component: () => import('@/views/Nested'),
        children: [
            // Subscription details
            // {
            //     path: ':id',
            //     name: 'subscription.details',
            //     component: () => import('@/views/subscription/Details'),
            //     props: true,
            // },
            // Create new subscription
            {
                path: 'create',
                name: 'subscription.create',
                component: () => import('@/views/subscription/SubscriptionCreate'),
                props: true,
            },
        ]
    },
]
