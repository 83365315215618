<template lang="pug">
.terms(v-if="dialog_visible")
    section
        .section-content-wrapper
            .description <i class="el-icon-check" /> Я принимаю <b><router-link :to="{name: 'agreement'}">Соглашение об использовании Сайта opt-in.ru</router-link></b>
            el-form(:model="agreement_form" @keydown.enter.native.prevent="" ref="agreement_form")
                .form-row(v-if="step === 1" )
                    el-button(@click="sendAcceptTermsCode" :disabled="subscriber.resend_timeout > 0" type="primary" size="small") Подтвердить кодом из SMS
                .form-row(v-if="step === 2" )
                    el-input(v-model="agreement_form.verification_code" maxlength="4" placeholder="Код" size="small" style="width: 5em;")
                    el-button(@click="postAcceptTermsCode" type="primary" size="small") Отправить
            .timeout(v-if="(step === 1) && (subscriber.resend_timeout > 0)") Отправка нового кода подтверждения станет возможна через {{ subscriber.resend_timeout }} сек.
            .timeout(v-if="(step === 2) && (subscriber.resend_timeout > 0)") Код подтверждения действителен {{ subscriber.resend_timeout }} сек.
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';

import {
    RESET_STORE,
    SET_SUBSCRIBER,
    SEND_ACCEPT_TERMS_CODE,
    POST_ACCEPT_TERMS_CODE
} from '@/store/types';""

const default_agreement_form = ()=>{
    return {
        verification_code: '',
    }
}

export default {
    data(){
        return {
            dialog_visible: false,
            is_on_agreement_page: false,
            resend_timeout: null,
            agreement_form: default_agreement_form()
        }
    },
    computed: {
        ...mapGetters([
            'subscriber',
        ]),
        step(){
            return this.subscriber.resend_timeout > 0 ? 2 : 1
        },
        title() {
            return 'Популярные отправители';
        },
    },
    created(){
        this.checkTermsAccepted();

        this.progressResendTimeout();
    },
    watch: {
        $route(){
            this.is_on_agreement_page = document.location.pathname === '/agreement';
            this.checkTermsAccepted();
        },
        'subscriber.check_terms_accepted': function (){
            this.checkTermsAccepted();
        }
    },
    methods: {
        ...mapActions([
            SEND_ACCEPT_TERMS_CODE,
            POST_ACCEPT_TERMS_CODE,
        ]),
        ...mapMutations([
            RESET_STORE,
            SET_SUBSCRIBER,
        ]),
        resetAgreementForm(){
            this.agreement_form = default_agreement_form();
        },
        checkTermsAccepted(){
            this.dialog_visible = !!(!this.subscriber.hasCompany() && this.subscriber.check_terms_accepted && this.subscriber.loggedIn() && !this.is_on_agreement_page && !this.subscriber.terms_accepted_at);
        },
        progressResendTimeout() {
            if(this.subscriber.resend_timeout > 0) {
                this.resend_timeout = window.setTimeout(() => {
                    this.SET_SUBSCRIBER({...this.subscriber, ...{resend_timeout: this.subscriber.resend_timeout - 1}});

                    this.progressResendTimeout()
                }, 1000);
            }
        },
        sendAcceptTermsCode(){
            if(this.subscriber.resend_timeout === 0) {
                this.SEND_ACCEPT_TERMS_CODE().then((subscriber) => {
                    this.SET_SUBSCRIBER({...this.subscriber, ...{resend_timeout: 60}});
                    this.progressResendTimeout();
                }).catch((e) => {
                    this.$message({
                        type: 'error',
                        message: e.message
                    });
                });
            }
        },
        postAcceptTermsCode(){
            this.POST_ACCEPT_TERMS_CODE(this.agreement_form).then(()=>{
                this.SET_SUBSCRIBER(Object.assign(this.subscriber, {
                    terms_accepted_at: this.$moment.now(),
                    check_terms_accepted: false,
                }));

                this.$message({
                    type: 'success',
                    message: 'Согласие принято'
                });
            }).catch((e)=>{
                this.agreement_form.verification_code = '';

                this.$message({
                    type: 'error',
                    message: 'Код подтверждения недействителен'
                });
            }).finally(()=>{
                this.resetAgreementForm();
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.terms{
    position: fixed;
    top: 0;
    z-index: 2000;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color--background;
    color: $color--black;

    .description{
        p{
            margin-bottom: 1em;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .el-form{
        width: 50%;
        margin-top: $padding12;

        @media (max-width: $max-width--tablets) {
            width: 100%;
        }

        .el-button{
            margin-left: 3px;
        }
    }

    .timeout{
        @include font--small;

        margin-top: $padding12;
    }

}
</style>
