export default  [
    {
        path: '/docs',
        meta: {
            tag: 'docs',
        },
        redirect: {
            name: 'docs.api',
        },
        component: () => import('@/views/Nested'),
        children: [
            {
                path: 'api',
                name: 'docs.api',
                component: () => import('@/views/docs/Api'),
            },
        ]
    },
]
