<template lang="pug">
  section.centered.how-it-works
    .section-content-wrapper
      .h2 Как это работает?
      div.steps
        div
          .title 1. Запомните имя отправителя, приславшего вам сообщение;
          .image
            img(src="/storage/how_it_works_1.png")
        div
          .title 2. Войдите в личный кабинет, подтвердив свой номер телефона полученным кодом;
          .image(:class="subscriber.loggedIn() ? 'transparent' : ''")
            .label
              i.el-icon-check
            img(src="/storage/how_it_works_2.png")
        div
          .title 3. Отпишитесь, найдя отправителя в списке, или добавив его самостоятельно.
          .image
            img(src="/storage/how_it_works_3.png")
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'subscriber',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.how-it-works {
  background: $color--primary;
  color: $color--black;

  .steps {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $padding14;
    align-items: end;

    .title {
      padding: $padding12 0;
    }

    .image {
      position: relative;

      img {
        max-width: 80%;
        margin: 0 auto;
      }

      .label {
        $size: 100px;

        @include round($size);

        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: - $size / 2;
        margin-left: - $size / 2;
        width: $size;
        height: $size;
        line-height: $size;
        font-size: $size / 1.5;
        background: $color--secondary;
      }

      &.transparent{
        .label {
          display: block;
        }
        img {
          opacity: .25;
        }
      }
    }

    @media (max-width: $max-width--tablets) {
      display: block;

      .title {
        padding: $padding12 0;
      }
    }
  }
}
</style>